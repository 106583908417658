.movieRow {
    display: flex;
    border-radius: 5px;
    background-color: var(--content);
    color: var(--text);
    padding: .8rem 1.5rem;
    margin-top: .5rem;
    cursor: pointer;
    transition: transform 50ms ease-in-out;
    user-select: none;
}

.movieRow p {
    margin: 0;
}

.movieRow .left {
    flex: 1;
    display: flex;
    flex-flow: row wrap;
    align-items: flex-start;
    margin-right: 0.5rem;
}

.movieRow .left .year {
    color: var(--text-secondary);
}

.movieRow .watch {
    color: var(--theme-color-text);
    display: flex;
    align-items: center;
}

.movieRow .watch .arrow {
    margin-left: .5rem;
    transition: transform 50ms ease-in-out;
    transform: translateY(.1rem);
}

.movieRow:active {
    transform: scale(1.02);
}

.movieRow:hover {
    background-color: var(--content-hover);
}

.movieRow:hover .watch .arrow {
    transform: translateX(.3rem) translateY(.1rem);
}

.attribute {
    color: var(--text);
    background-color: var(--theme-color);
    font-size: .75rem;
    padding: .25rem;
    border-radius: 10px;
    margin-right: 10px;
}

@media screen and (max-width: 400px) {
    .movieRow {
        flex-direction: column;
    }

    .movieRow .watch {
        margin-top: .5rem;
    }
}
