.inputBar {
    width: 100%;
    display: flex;
    height: 3rem;
}

.inputBar > *:first-child{
    border-radius: 0 !important;
    border-top-left-radius: 10px !important;
    border-bottom-left-radius: 10px !important;
}

.inputBar > *:last-child {
    border-radius: 0 !important;
    border-top-right-radius: 10px !important;
    border-bottom-right-radius: 10px !important;
}

.inputTextBox {
    border-width: 0;
    outline: none;
    background-color: var(--content);
    color: var(--text);
    padding: .7rem 1.5rem;
    height: auto;
    flex: 1;
    box-sizing: border-box;
}

.inputSearchButton {
    background-color: var(--button);
    border-width: 0;
    color: var(--text);
    padding: .5rem 2.1rem;

    font-weight: bold;
    cursor: pointer;
}

.inputSearchButton:hover {
    background-color: var(--button-hover);
}

.inputTextBox:hover {
    background-color: var(--content-hover);
}

.inputSearchButton .text > .arrow {
    opacity: 0;
    transition: opacity 0.2s ease-in-out, transform 0.2s ease-in-out;
    position: absolute;
    right: -0.8rem;
    bottom: -0.2rem;
}

.inputSearchButton .text {
    display: flex;
    position: relative;
    transition: transform 0.2s ease-in-out;
}

.inputSearchButton:hover .text > .arrow {
    transform: translateX(8px);
    opacity: 1;
}

.inputSearchButton:hover .text {
    transform: translateX(-10px);
}

.inputSearchButton:active {
    background-color: var(--button-active);
}

@media screen and (max-width: 700px) {
    .inputBar {
        flex-direction: column;
        align-items: flex-start;
        height: auto;
    }

    .inputBar > *:nth-child(n) {
        border-radius: 10px !important;
    }

    .inputSearchButton {
        margin-top: .5rem;
        align-self: center;
    }

    .inputTextBox {
        margin-top: .5rem;
        width: 100%;
    }
}
