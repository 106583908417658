.title {
    font-size: 2rem;
    color: var(--text);
    /* max-width: 20rem; */
    margin: 0;
    padding: 0;
    margin-bottom: 3.5rem;
}

.title-size-medium {
    font-size: 1.5rem;
}
.title-size-small {
    font-size: 1.1rem;
    color: #afb1b8;
}

.title-accent {
    color: var(--theme-color);
    font-weight: 600;
    margin: 0;
    padding: 0;
    margin-bottom: 0.5rem;
    margin-top: 1rem;
    display: inline-block;
}

.title-accent.title-accent-link {
    cursor: pointer;
}

.title-accent.title-accent-link .arrow {
    transition: transform 100ms ease-in-out;
    transform: translateY(.1rem);
    margin-right: .2rem;
}

.title-accent.title-accent-link:hover .arrow {
    transform: translateY(.1rem) translateX(-.5rem);
}

