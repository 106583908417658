.videoElement {
    width: 100%;
    background-color: #111117;
    border-radius: 5px;
}

.videoElementText {
    color: var(--text);
    margin: 0;
}
