.card {
    background-color: var(--card);
    padding: 3rem 4rem;
    margin: 0 3rem;
    border-radius: 10px;
    box-sizing: border-box;
    transition: height 500ms ease-in-out;
}

.card-wrapper.full {
    width: 81rem;
}

.card-wrapper {
  transition: height 500ms ease-in-out;
  overflow: hidden;
  width: 45rem;
  max-width: 100%;
}

@media screen and (max-width: 700px) {
    .card {
        margin: 0;
        padding: 3rem 2rem;
    }
}