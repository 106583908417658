.cardView {
    display: flex;
    min-height: 100vh;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    padding: 1rem;
    box-sizing: border-box;
}

.cardView > div {
    margin-top: 2rem;
}

.cardView > div:first-child {
    margin-top: 38px;
}

.topRightCredits {
    position: absolute;
    right: 1rem;
    top: 1rem;
    margin-top: 0 !important;
}

.topRightCredits a, .topRightCredits a:visited {
    color: var(--theme-color);
    text-decoration: none;
    margin: 0;
}
.topRightCredits a:hover, .topRightCredits a:active {
    color: #f19fd6;
    text-decoration: none;
}

.topRightCredits a .arrow {
    transform: translateY(.1rem);
}

.topRightCredits a:hover .arrow {
    transform: translateY(.1rem) translateX(.2rem);
}
